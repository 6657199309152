import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ['initialView', 'replacementView']

  showInitialView(evt) {
    evt.preventDefault();
    $(this.replacementViewTarget).addClass('hidden');
    $(this.initialViewTarget).removeClass('hidden');
  }

  showReplacementView(evt) {
    evt.preventDefault();
    $(this.replacementViewTarget).removeClass('hidden');
    $(this.initialViewTarget).addClass('hidden');
  }
}
