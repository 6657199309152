/**
 * Retrieves the record type and ID from the passed-in Stimulus.js data object.
 * @param  {Object} data The Stimulus.js controller data object. Likely just `this.data`.
 * @returns A tuple of { recordType, recordID }
 */
export function getRecordData(stimulusData) {
  const recordType = stimulusData.get("record-type");
  const recordID = stimulusData.get("record-id");
  if (!recordType || !recordID) {
    throw `Missing target record information: ${recordType}[${recordID}]`;
  }

  return { recordType, recordID };
}