import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table']

  /**
   * Called when the filter text field's content changes.
   */
  filterDataSet(evt) {
    const filterText = evt.target.value.toLowerCase().trim();
    const $rows = $(this.tableTarget).find('tbody tr');

    for (const r of $rows) {
      const rowText = $(r).attr('data-filter-text').toLowerCase().trim();

      if (rowText.match(filterText)) {
        $(r).removeClass('hidden');
      }
      else {
        $(r).addClass('hidden');
      }
    }
  }
}