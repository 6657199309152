import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["toggle"];
  static values = { class: { type: String, default: "hidden" } };

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {}

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    for (const t of this.toggleTargets) {
      $(t).addClass(this.classValue);
    }
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {}

  toggle(e) {
    $(e.currentTarget).addClass("hidden");
    for (const t of this.toggleTargets) {
      $(t).removeClass(this.classValue);
    }
  }
}
