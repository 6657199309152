import { Controller } from "@hotwired/stimulus";
import csrfToken from "../utilities/csrf_token.js";
import formatBytes from "../utilities/format_bytes.js";
import { digestMessage } from "../utilities/hash_file.js";
import { getRecordData } from "../utilities/form_data_helpers.js";
import { uploadNote } from "../utilities/change_notification_helper.js";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "chooseFilesButtonWrapper",
    "inputFileField",
    "cancelButton",
    "closeButtonWrapper",
    "notesField",
    "statusMessage",
    "tableBody",
    "tableWrapper",
    "uploadButton",
  ];

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {
    this.filesSelectedEventHandler = this.filesSelected.bind(this);
  }

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    this.inputFileFieldTarget.addEventListener("change", this.filesSelectedEventHandler, false);
    this.rowBgColor = "bg-white";
    this.fileUploads = [];
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {
    this.inputFileFieldTarget.removeEventListener("change", this.filesSelectedEventHandler);
  }

  showFilePicker(evt) {
    this.inputFileFieldTarget.click();
  }

  removeFile(evt) {
    const rowName = evt.target.dataset.rowName;
    $(this.tableWrapperTarget).find(`tr[data-row-name="${rowName}"]`).remove();
    this.fileUploads = this.fileUploads.filter((f) => f.rowName != rowName);
  }

  /**
   * Event handler that is invoked when the file input control selects files.
   * @param  {Event} event
   */
  async filesSelected(event) {
    const files = event.target.files;
    const $tbody = $(this.tableBodyTarget);

    $(this.tableWrapperTarget).removeClass("hidden");
    $(this.chooseFilesButtonWrapperTarget).addClass("hidden");
    $(this.uploadButtonTarget).removeAttr("disabled");

    for (let f of files) {
      f.rowName = await digestMessage(f.name + f.lastModifiedDate);
      this.fileUploads.push(f);
      const row = await this.#buildTableRow(f.rowName, f.name, f.size);
      $tbody.append(row);
    }
  }

  async #buildTableRow(rowName, fileName, size) {
    this.bgColor = this.bgColor == "bg-white" ? "bg-gray-50" : "bg-white";

    return `
      <tr class='${this.bgColor}' data-row-name="${rowName}">
        <td class='rov-table-cell'>${fileName}</td>
        <td class='rov-table-cell'>${formatBytes(size)}</td>
        <td class='rov-table-cell' data-cell='status'>Ready</td>
        <td class='rov-table-cell'>
          <button type="button" class="text-sm link-action--danger" data-action="click->file-uploader#removeFile" data-row-name="${rowName}">Delete</button>
        </td>
      </tr>
    `;
  }

  async updateUIStatus(rowName, statusText) {
    const encodedName = await digestMessage(rowName);
    $(this.tableWrapperTarget).find(`tr[data-row-name="${encodedName}"] td[data-cell=status]`).html(statusText);
  }

  /**
   * Called when the 'Upload' button receives a click event.
   * @param  {Event} evt Event object.
   */
  async uploadFiles(evt) {
    $(this.uploadButtonTarget).attr("disabled", "disabled");
    $(this.cancelButtonTarget).attr("disabled", "disabled");

    if (this.hasNotesFieldTarget) {
      $(this.notesFieldTarget).attr("disabled", "disabled");
      const noteText = $(this.notesFieldTarget).val();
      if (noteText.length > 0) {
        const { recordType, recordID } = getRecordData(this.data);
        await uploadNote(noteText, recordType, recordID);
      }
    }

    const totalCount = this.fileUploads.length;
    let currentUpload = 0;

    for (const f of this.fileUploads) {
      currentUpload += 1;
      $(this.statusMessageTarget).text(`Uploading ${currentUpload} of ${totalCount}`);
      await this.uploadFile(f);
    }

    $(this.statusMessageTarget).text("Upload complete!");
    $(this.uploadButtonTarget).addClass("hidden");
    $(this.cancelButtonTarget).addClass("hidden");
    $(this.closeButtonWrapperTarget).removeClass("hidden");
  }

  async uploadFile(file) {
    if (!file) {
      return null;
    }

    const { recordType, recordID } = getRecordData(this.data);

    const sharedAcrossFactory = this.data.get("shared-across-factory") == "true";

    this.updateUIStatus(file.name, "Uploading...");

    let formData = new FormData();
    formData.append("authenticity_token", csrfToken());
    formData.append("record_type", recordType);
    formData.append("record_id", recordID);
    formData.append(`campaign_file[file]`, file);
    formData.append(`campaign_file[file_name]`, file.name);
    formData.append(`campaign_file[shared_across_factory]`, sharedAcrossFactory);

    let result = await fetch("/campaign_files.json", {
      method: "POST",
      body: formData,
    });

    if (result.ok) {
      this.updateUIStatus(file.name, "Done");
    } else {
      this.updateUIStatus(file.name, JSON.parse(result.body).error);
    }

    return result;
  }
}
