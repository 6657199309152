import { Controller } from "@hotwired/stimulus";
import { debounce } from "../utilities/debounce";

export default class extends Controller {
  static targets = ["form", "input", "results"];

  initialize() {
    this.search = debounce(this.search.bind(this), 250);
  }

  search(evt) {
    this.formTarget.requestSubmit();
  }
}
