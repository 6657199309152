import { Controller } from "@hotwired/stimulus";
import { hashFile } from '../utilities/hash_file.js';
import globToRegex from '../utilities/glob_to_regex.js';
import csrfToken from '../utilities/csrf_token.js';
import { getRecordData } from "../utilities/form_data_helpers.js";
import { uploadNote } from "../utilities/change_notification_helper.js";
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'activityIndicator',
    'closeButton',
    'filePicker',
    'filePickerButton',
    'filesList',
    'notesField',
    'notesFieldWrapper',
    'statusMessage',
    'topButtonBar',
    'uploadButton',
    'fileCountContainer',
    'statusNew',
    'statusChanged',
    'statusUnchanged'
  ]

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {
    this.filesSelectedEventHandler = this.filesSelected.bind(this);
  }

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    this.filePickerTarget.addEventListener("change", this.filesSelectedEventHandler, false);
    this.rowBgColor = 'bg-white';
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {
    this.filePickerTarget.removeEventListener("change", this.filesSelectedEventHandler);
  }

  showFilePicker(evt) {
    this.filePickerTarget.click();
  }

  /**
   * Called when the 'Upload' button receives a click event.
   * @param  {Event} evt Event object.
   */
  async uploadChangedFiles(evt) {
    $(this.uploadButtonTarget).attr('disabled', 'disabled');
    $(this.notesFieldTarget).attr("disabled", "disabled");

    const noteText = $(this.notesFieldTarget).val();

    const totalCount = this.fileUploads.length;
    let currentUpload = 0;

    for (const f of this.fileUploads) {
      currentUpload += 1;
      $(this.statusMessageTarget).text(`Uploading ${currentUpload} of ${totalCount}`);
      await this.uploadFile(f, noteText);
    }

    $(this.statusMessageTarget).text("Upload complete!");
    $(this.uploadButtonTarget).addClass('hidden');
    $(this.closeButtonTarget).removeClass('hidden');
  }

  async uploadFile(fileUploadData, noteText) {
    const file = fileUploadData.file;
    const hashValue = fileUploadData.hashValue;
    const parentCampaignID = fileUploadData.parentCampaignID;

    if (!file || !hashValue || !parentCampaignID) {
      return null;
    }

    const { recordType, recordID } = getRecordData(this.data);

    this.updateUIStatus(hashValue, 'Uploading...');

    if (noteText.length > 0) {
      await uploadNote(noteText, "parent_campaign", parentCampaignID);
    }

    const targetModelName = this.data.get('model-name');

    let formData = new FormData();
    formData.append('authenticity_token', csrfToken());
    formData.append('record_type', recordType);
    formData.append('record_id', recordID);
    formData.append('attachment[]', JSON.stringify({record_type: "parent_campaign", record_id: parentCampaignID}))
    formData.append(`${targetModelName}[file]`, file);
    formData.append(`${targetModelName}[hash_value]`, hashValue);
    formData.append(`${targetModelName}[file_name]`, file.name);
    formData.append(`${targetModelName}[last_modified_timestamp]`, file.lastModified.toString());

    const url = this.data.get('url');
    let result = await fetch(url, {
      method: 'POST',
      body: formData
    });

    if (result.ok) {
      this.updateUIStatus(hashValue, "Done");
    }
    else {
      this.updateUIStatus(hashValue, JSON.parse(result.body).error);
    }

    return result;
  }

  /**
   * Examines the list of prospective files to be uploaded:
   *   1. It filters them using the regex object.
   *   2. It compares and further filters them by comparing them with syncedFilesAndHashes.
   *
   * Finally, it returns a hash of { fileUploads, unmodifiedFiles }.
   *
   * @param  {RegExp} staticFileRegex
   * @param  {Object} syncedFilesAndHashes
   * @param  {File[]} files
   */
  async identifyChangedFiles(staticFileRegex, syncedFilesAndHashes, parentCampaigns, files) {
    let fileUploads = [];
    let unmodifiedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = file.webkitRelativePath;
      const matches = path.match(staticFileRegex);

      if (!matches || !matches.groups) {
        continue;
      }

      const parentCampaignName = matches.groups['filename'];
      const parentCampaignID = parentCampaigns[parentCampaignName.toLowerCase()]?.id;

      if (!parentCampaignID) {
        continue;
      }

      const hashValue = await hashFile(file);
      const syncedFile = syncedFilesAndHashes[file.name];

      if (syncedFile?.hashValue != hashValue && syncedFile?.last_modified_timestamp != file.lastModified.toString()) {
        fileUploads.push({ file, path, parentCampaignName, parentCampaignID, hashValue });
      }
      else {
        unmodifiedFiles.push({ file, path, parentCampaignName, parentCampaignID, hashValue });
      }
    }

    fileUploads = fileUploads.sort((l, r) => {
      const leftFilename = l.file.name.toLowerCase();
      const rightFilename = r.file.name.toLowerCase();
      if (leftFilename < rightFilename) {
        return -1;
      }
      else if (rightFilename < leftFilename) {
        return 1;
      }
      else {
        return 0;
      }
    });

    return { fileUploads, unmodifiedFiles };
  }

  /**
   * Event handler that is invoked when the file input control selects files.
   * @param  {Event} event
   */
  async filesSelected(event) {
    const staticFileRegex = this.#getStaticFileRegex();
    const syncedFilesAndHashes = JSON.parse(this.data.get('file-hashes'));
    const parentCampaigns = JSON.parse(this.data.get('parent-campaigns'));

    $(this.topButtonBarTarget).addClass('hidden');
    $(this.activityIndicatorTarget).removeClass('hidden');

    let { fileUploads, unmodifiedFiles } = await this.identifyChangedFiles(
      staticFileRegex,
      syncedFilesAndHashes,
      parentCampaigns,
      event.target.files
    );

    $(this.activityIndicatorTarget).addClass('hidden');
    $(this.filesListTarget).removeClass('hidden');

    this.fileUploads = fileUploads;

    if (this.fileUploads.length > 0) {
      $(this.uploadButtonTarget).removeClass('hidden');
      $(this.notesFieldWrapperTarget).removeClass('hidden');
    }
    else {
      $(this.topButtonBarTarget).removeClass('hidden');
    }

    let $tbody = $(this.filesListTarget).find('tbody');

    let changedCount = 0;
    let newCount = 0;

    for (const { file, path, parentCampaignName, hashValue } of fileUploads) {
      const parentCampaign = parentCampaigns[parentCampaignName.toLowerCase()];

      let actionText = null;
      if (syncedFilesAndHashes[file.name]) {
        actionText = "Modified";
        changedCount += 1;
      }
      else {
        actionText = "New File";
        newCount += 1;
      }

      $tbody.append(this.buildFilesListRow(file.name, path, parentCampaign, actionText, hashValue, true));
    }

    for (const { file, path, parentCampaignName } of unmodifiedFiles) {
      const parentCampaign = parentCampaigns[parentCampaignName.toLowerCase()];
      $tbody.append(this.buildFilesListRow(file.name, path, parentCampaign, "Unchanged", '', false));
    }

    $(this.fileCountContainerTarget).removeClass('hidden');
    $(this.statusMessageTarget).html(`Files to update: ${this.fileUploads.length}`);
    $(this.statusNewTarget).html(newCount);
    $(this.statusChangedTarget).html(changedCount);
    $(this.statusUnchangedTarget).html(unmodifiedFiles.length);
  }

  buildFilesListRow(fileName, path, parentCampaign, actionText, hashValue, modified) {
    this.bgColor = (this.bgColor == "bg-white" ? "bg-gray-50" : "bg-white");

    const parentCampaignName = !!parentCampaign ? parentCampaign.name : "";
    const uploadStatus = modified ? 'Ready to Upload' : '';

    return `
      <tr class='${this.bgColor}'>
        <td class='p-0.5' title='${path}'>${fileName}</td>
        <td class='p-0.5'>${parentCampaignName}</td>
        <td class='p-0.5'>${actionText}</td>
        <td class='p-0.5' data-hash-value='${hashValue}'>${uploadStatus}</td>
      </tr>
    `;
  }

  updateUIStatus(hashValue, statusText) {
    $(this.filesListTarget).find(`td[data-hash-value=${hashValue}]`).html(statusText);
  }

  /**
   * Returns a regex object based on the availability of the
   * file-glob or file-regex data attribute.
   * @returns {RegExp|*}
   */
  #getStaticFileRegex() {
    const fileGlob = this.data.get('file-glob');
    const fileRegex = this.data.get('file-regex');
    return fileGlob ? globToRegex(fileGlob) : new RegExp(fileRegex);
  }
}
