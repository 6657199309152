import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['checkbox', 'content'];

    connect() {
        this.toggle();
    }

    toggle() {
        if (this.#isChecked) {
            this.contentTarget.classList.remove('hidden');
        } else {
            this.contentTarget.classList.add('hidden');
        }
    }

    get #isChecked() {
        return this.checkboxTarget['checked'];
    }
}

