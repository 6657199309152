import { Controller } from "@hotwired/stimulus"
import { CountUp } from 'countup.js'

export default class extends Controller {
  static targets = ['counter']

  connect() {
    let counterElt = $(this.counterTarget);
    counterElt.css({'width': counterElt.width(), 'display': 'inline-block'});
    let targetValue = parseInt(counterElt.html().replace(/,/g, ''), 10);
    let options = {};
    let animator = new CountUp(counterElt[0],targetValue,{
      startVal: targetValue - 100000,
      smartEasingThreshold: 1000,
      smartEasingAmount: 1000,
      useEasing: true
    });
    animator.start();
  }
}

