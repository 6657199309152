// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@github/relative-time-element";
import "channels";

import "./turbostream";
import "./controllers";
import "./css/app.css";
import "driver.js/dist/driver.css";

import ahoy from "ahoy.js";

import $ from "jquery";
global.$ = $;

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

try {
  require("trix");
}
catch (e) {
  console.log(e);
}

import "@rails/actiontext";

document.addEventListener("turbo:load", function () {
  $(".force-target-blank .trix-content a").click(function (e) {
    $(this).attr("target", "_blank");
    $(this).attr("rel", "noopener noreferrer");
  });
});
