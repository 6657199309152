// <div class="form-group" data-controller='select2'>
//   <%= f.select :user_id, User.all.map { |user| user.name }, {include_blank: false, required: true, include_hidden: false}, class: 'form-control content-search' %>
// </div>

import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

require("select2/dist/css/select2")

import Select2 from "select2"

export default class extends Controller {
  connect() {
    const ajaxURL = this.data.get("ajax-url");

    let opts = {};

    if (ajaxURL) {
      opts = {
        ajax: {
          delay: 250,
          url: ajaxURL,
          dataType: 'json',
          processResults: (data) => {
            const mappedData = data.map((elt) => {
              return {id: elt.id, text: elt.display_name};
            });
            return {results: mappedData};
          }
        }
      };
    }

    $('.rov-select2-wrapper select').select2(opts);
  }
}
