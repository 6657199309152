// controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus";
import { computePosition, offset, flip, shift } from "@floating-ui/dom";

/**
 * A controller that manages a dropdown menu. Use the Dropdown::MenuComponent
 * ViewComponent instead of constructing this manually.
 */
export default class extends Controller {
  static targets = ["button", "menu"];

  connect() {
    this.toggle = this.toggle.bind(this);
    this.positionMenu = this.positionMenu.bind(this);
    document.addEventListener("click", this.close.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.close.bind(this));
  }

  toggle(event) {
    event.stopPropagation();
    if (this.menuTarget.classList.contains("hidden")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.menuTarget.classList.remove("hidden");
    this.positionMenu();
  }

  close(event) {
    // if you are using a 'classic' modal where the modal content is actually
    // contained inside of the dropdown, closing the dropdown will also 'close'
    // the modal just as it's presented. This is a workaround to prevent that.
    // This can be removed if/when all of the modals are converted to the new
    // `content_for` system.
    if (event && this.element.contains(event.target)) {
      return;
    }
    this.menuTarget.classList.add("hidden");
  }

  forceClose(event) {
    this.menuTarget.classList.add("hidden");
  }

  async positionMenu() {
    const { x, y } = await computePosition(this.buttonTarget, this.menuTarget, {
      placement: 'bottom-start',
      middleware: [offset(0), flip(), shift()],
    });

    Object.assign(this.menuTarget.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  }
}