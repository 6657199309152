import { Controller } from "@hotwired/stimulus";
import $ from 'jquery';
/**
* Given a 'master-details' UI, this controller manages the selection of
* master elements. When a new master element is selected, the controller
* will mark the previous master element as 'unselected' and mark the new
* master element as 'selected'.
*/
export default class extends Controller {
  static targets = ["masterContainer"];

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {}

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {}

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {}

  select(e) {
    $(this.masterContainerTarget).find('.selected').removeClass('selected');
    $(e.currentTarget).addClass('selected');
    $(e.currentTarget).find('.unread').addClass('hidden');
  }
}
