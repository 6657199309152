import { Controller } from "@hotwired/stimulus";

// Code adapted from https://htmldom.dev/drag-and-drop-table-column/
export default class extends Controller {
  static targets = [
    "availableColumns",
    "form",
    "outputMappingsField",
    "renameFieldName",
    "renameDisplayName",
    "saveButton",
    "table"
  ];
  static values = {
    availableColumns: Array
  };

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {}

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    for (const {displayName, fieldName} of this.availableColumnsValue) {
      const opt = document.createElement('option');
      opt.value = fieldName;
      opt.innerText = displayName;
      this.availableColumnsTarget.appendChild(opt);
    }

    this.#connectDragAndDrop();
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {}

  addColumn(e) {
    const fieldName = this.availableColumnsTarget.value;
    const displayName = this.availableColumnsTarget.options[this.availableColumnsTarget.selectedIndex].text;
    this.#updateColumns(fieldName, displayName);
  }

  deleteColumn(e) {
    const fieldName = e.currentTarget.getAttribute('data-delete-field-name');
    const cells = this.tableTarget.querySelectorAll(`[data-field-name="${fieldName}"]`);
    for (const c of Array.from(cells)) {
      c.remove();
    }

    this.#updateColumns();
  }

  prepareForRename(e) {
    const fieldName = e.currentTarget.getAttribute('data-mapping-field-name');
    const displayName = e.currentTarget.getAttribute('data-mapping-display-name');
    this.renameDisplayNameTarget.value = displayName;
    this.renameFieldNameTarget.value = fieldName;
  }

  commitRename(e) {
    const displayName = this.renameDisplayNameTarget.value;
    const fieldName = this.renameFieldNameTarget.value;

    this.#updateColumns(fieldName, displayName);
  }

  #updateColumns(addedFieldName=null, addedDisplayName=null) {
    let tableHeaderFieldOrder = this.currentColumns;

    if (addedFieldName && addedDisplayName) {
      let matched = false;
      for (let i=0; i<tableHeaderFieldOrder.length; i++) {
        const {field_name} = tableHeaderFieldOrder[i];
        if (field_name == addedFieldName) {
          tableHeaderFieldOrder[i] = {field_name: field_name, display_name: addedDisplayName};
          matched = true;
          break;
        }
      }
      if (!matched) {
        tableHeaderFieldOrder.push({field_name: addedFieldName, display_name: addedDisplayName});
      }
    }

    const jsonBlob = JSON.stringify(tableHeaderFieldOrder);
    this.outputMappingsFieldTarget.setAttribute("value", jsonBlob);
    this.formTarget.submit();
  }

  get currentColumns() {
    const tableHeaders = Array.from(this.tableTarget.querySelectorAll("th"));
    return tableHeaders.map((th) => {
      return {
        field_name: th.getAttribute("data-field-name"),
        display_name: th.getAttribute("data-display-name"),
      };
    });
  }

  #connectDragAndDrop() {
    const table = this.tableTarget;

    let draggingEle;
    let draggingColumnIndex;
    let placeholder;
    let list;
    let isDraggingStarted = false;

    // The current position of mouse relative to the dragging element
    let x = 0;
    let y = 0;

    // Swap two nodes
    const swap = (nodeA, nodeB) => {
      const parentA = nodeA.parentNode;
      const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

      // Move `nodeA` to before the `nodeB`
      nodeB.parentNode.insertBefore(nodeA, nodeB);

      // Move `nodeB` to before the sibling of `nodeA`
      parentA.insertBefore(nodeB, siblingA);
    };

    // Check if `nodeA` is on the left of `nodeB`
    const isOnLeft = (nodeA, nodeB) => {
      // Get the bounding rectangle of nodes
      const rectA = nodeA.getBoundingClientRect();
      const rectB = nodeB.getBoundingClientRect();

      return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
    };

    const cloneTable = () => {
      const rect = table.getBoundingClientRect();

      list = document.createElement("div");
      list.classList.add("clone-list");
      list.style.position = "absolute";
      list.style.left = `${rect.left}px`;
      list.style.top = `${rect.top}px`;
      table.parentNode.insertBefore(list, table);

      // Hide the original table
      table.style.visibility = "hidden";

      // Get all cells
      const originalCells = [].slice.call(table.querySelectorAll("tbody td"));

      const originalHeaderCells = [].slice.call(table.querySelectorAll("th"));
      const numColumns = originalHeaderCells.length;

      // Loop through the header cells
      originalHeaderCells.forEach(function (headerCell, headerIndex) {
        const width = parseInt(window.getComputedStyle(headerCell).width);

        // Create a new table from given row
        const item = document.createElement("div");
        item.classList.add("draggable");

        const newTable = document.createElement("table");
        newTable.setAttribute("class", "clone-table");
        newTable.style.width = `${width}px`;

        // Header
        const th = headerCell.cloneNode(true);
        let newRow = document.createElement("tr");
        newRow.appendChild(th);
        newTable.appendChild(newRow);

        const cells = originalCells.filter(function (c, idx) {
          return (idx - headerIndex) % numColumns === 0;
        });
        cells.forEach(function (cell) {
          const newCell = cell.cloneNode(true);
          newCell.style.width = `${width}px`;
          newRow = document.createElement("tr");
          newRow.appendChild(newCell);
          newTable.appendChild(newRow);
        });

        item.appendChild(newTable);
        list.appendChild(item);
      });
    };

    const mouseDownHandler = (e) => {
      const closestHeader = e.target.closest('th');
      draggingColumnIndex = [].slice.call(table.querySelectorAll("th")).indexOf(closestHeader);

      if (draggingColumnIndex < 0) {
        return;
      }

      // Determine the mouse position
      x = e.clientX - closestHeader.offsetLeft;
      y = e.clientY - closestHeader.offsetTop;

      // Attach the listeners to `document`
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      if (!isDraggingStarted) {
        isDraggingStarted = true;

        cloneTable();

        draggingEle = [].slice.call(list.children)[draggingColumnIndex];
        draggingEle.classList.add("select-none");

        // Let the placeholder take the height of dragging element
        // So the next element won't move to the left or right
        // to fill the dragging element space
        placeholder = document.createElement("div");
        placeholder.classList.add("placeholder");
        draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);
        placeholder.style.width = `${draggingEle.offsetWidth}px`;
      }

      // Set position for dragging element
      draggingEle.style.position = "absolute";
      draggingEle.style.top = `${draggingEle.offsetTop + e.clientY - y}px`;
      draggingEle.style.left = `${draggingEle.offsetLeft + e.clientX - x}px`;

      // Reassign the position of mouse
      x = e.clientX;
      y = e.clientY;

      // The current order
      // prevEle
      // draggingEle
      // placeholder
      // nextEle
      const prevEle = draggingEle.previousElementSibling;
      const nextEle = placeholder.nextElementSibling;

      // // The dragging element is above the previous element
      // // User moves the dragging element to the left
      if (prevEle && isOnLeft(draggingEle, prevEle)) {
        // The current order    -> The new order
        // prevEle              -> placeholder
        // draggingEle          -> draggingEle
        // placeholder          -> prevEle
        swap(placeholder, draggingEle);
        swap(placeholder, prevEle);
        return;
      }

      // The dragging element is below the next element
      // User moves the dragging element to the bottom
      if (nextEle && isOnLeft(nextEle, draggingEle)) {
        // The current order    -> The new order
        // draggingEle          -> nextEle
        // placeholder          -> placeholder
        // nextEle              -> draggingEle
        swap(nextEle, placeholder);
        swap(nextEle, draggingEle);
      }
    };

    const mouseUpHandler = () => {
      if (!isDraggingStarted) {
        return;
      }
      // // Remove the placeholder
      placeholder && placeholder.parentNode.removeChild(placeholder);

      draggingEle.classList.remove("select-none");
      draggingEle.style.removeProperty("top");
      draggingEle.style.removeProperty("left");
      draggingEle.style.removeProperty("position");

      // Get the end index
      const endColumnIndex = [].slice.call(list.children).indexOf(draggingEle);

      isDraggingStarted = false;

      // Remove the `list` element
      list.parentNode.removeChild(list);

      // Move the dragged column to `endColumnIndex`
      table.querySelectorAll("tr").forEach(function (row) {
        const cells = [].slice.call(row.querySelectorAll("th, td"));
        draggingColumnIndex > endColumnIndex
          ? cells[endColumnIndex].parentNode.insertBefore(cells[draggingColumnIndex], cells[endColumnIndex])
          : cells[endColumnIndex].parentNode.insertBefore(
              cells[draggingColumnIndex],
              cells[endColumnIndex].nextSibling
            );
      });

      // Bring back the table
      table.style.removeProperty("visibility");

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);

      this.#updateColumns();
    };

    table.querySelectorAll("th .drag-source").forEach((headerCell) => {
      headerCell.classList.add("draggable");
      headerCell.addEventListener("mousedown", mouseDownHandler);
    });
  }
}
