import {driver} from "driver.js";
import {Controller} from "@hotwired/stimulus";
import {buildStep} from "../utilities/product_tour_helpers";
import {setPermanentCookie} from "../utilities/cookie_helpers";
import Cookies from "js-cookie";

const NAV_BAR_TOUR_COOKIE = "rov-nav-bar-tour-june-2024";

export default class extends Controller {

  connect() {
    if (this.#canShowTour()) {
      this.#startTour();
    }
  }

  #startTour() {
    let driverObj = driver({
      showProgress: true,
      steps: this.#buildSteps(),
      onDestroyed: () => {
        setPermanentCookie(NAV_BAR_TOUR_COOKIE, "1");
      }
    });

    driverObj.drive();
  }

  #canShowTour() {
    const tourSeen = Cookies.get(NAV_BAR_TOUR_COOKIE) == "1";
    if (tourSeen) { return false; }

    const correctBreakpoint = window.innerWidth >= 768;
    if (!correctBreakpoint) { return false; }

    const regex = /^\/(organizations|enterprises)\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/?$/;
    const correctPage = regex.test(window.location.pathname);

    return correctPage;
  }

  #buildSteps() {
    let step0 = buildStep(
        null,
        'Welcome to Sincere!',
        'We are excited to show you around the site. Click the "Next" button to get started.'
    );

    let step1 = buildStep(
        '#rov-org-picker',
        'Current Organization',
        'This is your current organization. Click here to see all of your organizations.'
    );

    let step2 = buildStep(
        "#rov-notifications-button",
        "Notifications",
        "Click here to see your notifications."
    );

    let step3 = buildStep(
        "#rov-user-dropdown",
        "All about you!",
        "Click here to log out, edit your account, get help, and more."
    );

    return [step0, step1, step2, step3];
  }
}