import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['togglers', 'content', 'secondaryValue'];

  connect() {
    for (let target of this.contentTargets) {
      if ($(target).attr('data-radio-id') === this._selectedID()) {
        $(target).removeClass('hidden');
      }
    }

    if (this.hasSecondaryValueTarget && this._secondaryValue()) {
      this.secondaryValueTarget.value = this._secondaryValue();
    }
  }

  _selectedField() {
    return $(this.togglersTarget).find("input[type='radio']:checked");
  }

  _selectedID() {
    return this._selectedField().attr('id');
  }

  _secondaryValue() {
    return this._selectedField().attr('data-secondary-value');
  }

  toggle() {
    const selectedID = this._selectedID();

    for (let target of this.contentTargets) {
      if ($(target).attr('data-radio-id') === selectedID) {
        $(target).removeClass('hidden');
        if (this.hasSecondaryValueTarget && this._secondaryValue()) {
          this.secondaryValueTarget.value = this._secondaryValue();
        }
      }
      else {
        $(target).addClass('hidden');
      }
    }
  }
}

