import csrfToken from './csrf_token.js'

export async function uploadNote(noteText, recordType, recordID) {
  let formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("record_type", recordType);
  formData.append("record_id", recordID);
  formData.append("change_notification[description]", noteText);

  let result = await fetch("/change_notifications.json", {
    method: "POST",
    body: formData,
  });

  return result;
}