import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = [ "checkbox" ];
  static values = {
    defaultChecked: { type: String, default: '1' },
    reloadElement: String
  };

  connect() {
    const cookieName = this.checkboxTarget.name;
    const currentVal = Cookies.get(cookieName) ?? this.defaultCheckedValue;
    this.checkboxTarget.checked = (currentVal == "1");
  }

  triggerSubmit() {
    const newValue = this.checkboxTarget.checked ? "1" : "0";
    Cookies.set(this.checkboxTarget.name, newValue, { expires: 365 * 20 });

    if (this.reloadElementValue) {
      const frameElt = document.getElementById(this.reloadElementValue);
      frameElt.reload();
    }
  }
}