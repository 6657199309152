import { Turbo } from "@hotwired/turbo-rails";

Turbo.start();

Turbo.StreamActions.turbo_frame_reload = function() {
    console.log("turbo_frame_reload", this.targetElements);
    this.targetElements.forEach((element) => element.reload());
};

Turbo.StreamActions.log = function () {
    const message = this.getAttribute('message');
    console.log("TurboStreamLog", message);
}
