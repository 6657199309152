import { Application } from "@hotwired/stimulus";

const stimulusApp = window.stimulusApp ?? Application.start();
window.stimulusApp = stimulusApp;

// Controllers

import Alert from "./alert_controller.js";
stimulusApp.register("alert", Alert);

import BulkImport from "./bulk_import_controller.js";
stimulusApp.register("bulk-import", BulkImport);

import CheckboxToggler from "./checkbox_toggler_controller.js";
stimulusApp.register("checkbox-toggler", CheckboxToggler);

import ClassToggler from "./class_toggler_controller.js";
stimulusApp.register("class-toggler", ClassToggler);

import Clipboard from "./clipboard_controller.js";
stimulusApp.register("clipboard", Clipboard);

import ContentSwap from "./content_swap_controller.js";
stimulusApp.register("content-swap", ContentSwap);

import CookieToggle from "./cookie_toggle_controller.js";
stimulusApp.register("cookie-toggle", CookieToggle);

import CountUp from "./count_up_controller.js";
stimulusApp.register("count-up", CountUp);

import DiffingUploader from "./diffing_uploader_controller.js";
stimulusApp.register("diffing-uploader", DiffingUploader);

import Dismissable from "./dismissable_controller.js";
stimulusApp.register("dismissable", Dismissable);

import Dropdown from "./dropdown_controller.js";
stimulusApp.register("dropdown", Dropdown);

import EventDispatcher from "./event_dispatcher_controller.js";
stimulusApp.register("event-dispatcher", EventDispatcher);

import FileUploader from "./file_uploader_controller.js";
stimulusApp.register("file-uploader", FileUploader);

import FormAutoSubmitController from "./form_auto_submit_controller.js";
stimulusApp.register("form-auto-submit", FormAutoSubmitController);

import Hamburger from "./hamburger_controller.js";
stimulusApp.register("hamburger", Hamburger);

import Multiselect from "./multiselect_controller.js";
stimulusApp.register("multiselect", Multiselect);

import OutputMapper from "./output_mapper_controller.js";
stimulusApp.register("output-mapper", OutputMapper);

import NavigationBarTour from "./navigation_bar_tour_controller.js";
stimulusApp.register("navigation-bar-tour", NavigationBarTour);

import Select2Controller from "./select2_controller.js";
stimulusApp.register("select2", Select2Controller);

import SelectionListController from "./selection_list_controller.js";
stimulusApp.register("selection-list", SelectionListController);

import TrixAugmenter from "./trix_augmenter_controller.js";
stimulusApp.register("trix-augmenter", TrixAugmenter);

import TypeaheadSearch from "./typeahead_search_controller.js";
stimulusApp.register("typeahead-search", TypeaheadSearch);

import ViewToggler from "./view_toggler_controller.js";
stimulusApp.register("view-toggler", ViewToggler);

import Modal from "./modal_controller.js";
stimulusApp.register("modal", Modal);

import TableFilter from "./table_filter_controller.js";
stimulusApp.register("table-filter", TableFilter);

import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.css";
stimulusApp.register("flatpickr", Flatpickr);

import { Tabs } from "tailwindcss-stimulus-components";
stimulusApp.register("tabs", Tabs);