import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["container"];

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {}

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    for (const att of $("action-text-attachment")) {
      const $att = $(att);
      const width = parseInt($att.attr("width"));
      const height = parseInt($att.attr("height"));

      if (!width || !height) {
        continue;
      }

      const $img = $att.find("img");
      $img.attr("width", width / 2);
      $img.attr("height", height / 2);
    }
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {}
}
