import { Controller } from "@hotwired/stimulus";

/**
 * Submits the formTarget whenever a field contained within the form invokes the triggerSubmit() action.
 * This is useful for building a form-driven filtering UI alongside a table.
 */
export default class extends Controller {
  static targets = ["form"];

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {}

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {}

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {}

  triggerSubmit(e) {
    this.formTarget.submit();
  }
}
