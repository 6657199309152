import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'rowCheckbox',
    'selectAll',
    'table'
  ]

  selectAllVisible(evt) {
    for (const c of this.rowCheckboxTargets) {
      const $c = $(c);
      if ($c.is(':visible')) {
        $c.attr('checked', 'checked');
      }
    }
  }

  deselectAll(evt) {
    for (const c of this.rowCheckboxTargets) {
      $(c).removeAttr('checked');
    }
  }
}