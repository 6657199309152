import { Controller } from "@hotwired/stimulus";

/**
 * This controller is used to dispatch custom events. The primary use case is to
 * trigger a modal to open from a different part of the UI.
 *
 * <button
 *   type="button"
 *   data-controller="event-dispatcher"
 *   data-action="click->event-dispatcher#trigger"
 *   data-event-dispatcher-event-value="rov:show-modal"
 *   data-event-dispatcher-id-value="rov-modal-notifications"
 *   >
 *     Notifications
 *   </button>
 *
 *   <%= render Modals::ModalDialogComponent.new(
 *     modal_title: "Notifications",
 *     modal_id: "rov-modal-notifications",
 *    ) do |c| %>
 *      <% c.with_modal_content do %>
 *        my modal content.
 *      <% end %>
 *    <% end %>
 */
export default class extends Controller {
  static values = {
    event: String,
    id: String,
  };

  trigger() {
    const event = new CustomEvent(
        this.eventValue,
        { detail: { id: this.idValue } }
    );

    window.dispatchEvent(event);
  }
}