import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "copiedMessage", "preclick_icon", "postclick_icon" ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.copiedMessageTarget.classList.remove("hidden");
    this.preclick_iconTarget.classList.add("!hidden");
    this.postclick_iconTarget.classList.remove("!hidden");
  }
}