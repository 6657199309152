import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'saveButton',
    'table'
  ]

  /**
   * Called exactly once when the controller is first instantiated.
   */
  initialize() {
  }

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    // Update the available columns when a select box will be displayed.
    $(this.tableTarget).find('select').on('mouseover keydown', (evt) => this.#configureSelectState(evt));

    // Only enable the 'update' button after something changes.
    $(this.tableTarget).find('select').on('change', () => {
      $(this.saveButtonTarget).removeAttr('disabled');
      this.#updateOptionEnabledStatus();
    });

    // Get initial state set up properly.
    this.#updateOptionEnabledStatus();
  }

  /**
   * Called anytime the controller is disconnected from the DOM
   */
  disconnect() {
  }

  // Configure the enabled and disabled options in a select box.
  #configureSelectState(evt) {
    let options = $(evt.currentTarget).find('option');
    for (const opt of options) {
      const val = opt['value'];
      const isSelected = !!opt['selected'];
      if (this.selectedOptions.has(val) && !isSelected) {
        opt['disabled'] = 'disabled';
      }
      else {
        delete opt['disabled'];
      }
    }
  }

  // Updates the set of in-use option values.
  #updateOptionEnabledStatus() {
    let $selections = $(this.tableTarget).find('select option:selected');
    let uniqueSelections = new Set();
    for (const sel of $selections) {
      const val = sel['value'].trim();
      if (val.length > 0) {
        uniqueSelections.add(val);
      }
    }

    this.selectedOptions = uniqueSelections;
  }
}