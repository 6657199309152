import { Controller } from "@hotwired/stimulus";
import {enter, leave} from 'el-transition';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'alert'
  ];

  /**
   * Called anytime the controller is connected to the DOM
   */
  connect() {
    this.alertTimeouts = {};
  }

  alertTargetConnected(t) {
    setTimeout(() => {
      enter(t);

      this.alertTimeouts[alert] = setTimeout(() => {
        leave(t);
      }, 10000);
    }, 250);
  }

  pauseDismiss(evt) {
    const timeoutID = this.alertTimeouts[this.#targetFromEvent(evt)];
    clearInterval(timeoutID);
  }

  resumeDismiss(evt) {
    const target = this.#targetFromEvent(evt);
    this.alertTimeouts[target] = setTimeout(() => {
      leave(target);
    }, 10000);
  }

  dismiss(evt) {
    const target = this.#targetFromEvent(evt);
    const timeoutID = this.alertTimeouts[target];
    clearTimeout(timeoutID);
    this.alertTimeouts[target] = null;
    leave(target);
  }

  #targetFromEvent(evt) {
    return $(evt.target).closest('[data-alert-target]')[0];
  }
}
